@import 'scss/fonts';
@import 'scss/root_vars';
// @import 'scss/theming';
@import './vendor/sciflow-theming';
@import 'scss/basscss_vars';
@import 'scss/footnotes';
@import 'scss/document_outline';

body {
  @import 'scss/utils';
  @import 'scss/basscss';
}

@import './plugins';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Roboto';
  font-size: 16px;
  background-color: rgba(var(--background-background), 1);
}

a {
  color: var(--background-background);
  cursor: pointer;
  text-decoration: underline;
}

.rounded {
  border-radius: 1rem;
}

// Firefox support
strong {
  font-weight: bold;
}

/* focus on selected citation from Reference in Text(DCA) */
cite:focus {
  background-color: yellow;
}

/* a container with flex-direction column */
.container-scrollable {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* this is the flex container that will take the rest of the height */
.container-scrollable-wrapper {
  display: flex;
  flex: 1;
  min-height: 0px;
  /* IMPORTANT: need this for non-chrome browsers */
}

/* the overflow for our overflowed content */
.container-scrollable-overflow {
  flex: 1;
  overflow: auto;
}

/* the overflow content itself */
.editor-content {
  display: flex;
  justify-content: space-between;
}

.editor {
  max-width: calc(100% - 80px);
  margin: 0 auto;
}

.editor-toolbar {
  position: sticky;
  z-index: 50;
  width: 100%;
  top: 0;
  text-align: center;
  height: auto;
  flex-wrap: wrap;
  padding: 0;
}

.editor-toolbar > .mat-toolbar {
  height: auto;
}

// This makes the editor centered
.editor-left-flex {
  width: var(--dca-menu-width); // same as dca-menu
  flex-grow: 0;
  flex-shrink: 0;
}

// this is the mat-drawer-container hodling editor and dca
.mat-drawer-container.editor-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dca-menu {
  position: sticky;
  top: 0;
  right: 0;
  width: var(--dca-menu-width);
  z-index: 100;
  align-self: flex-start;
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(100vh - 64px);
  background-color: rgba(var(--background-background), 1);
}

.dca-area {
  width: var(--dca-width);

  &.mat-drawer {
    width: var(--dca-width);
  }
}

// .dca-opened {
//   overflow: hidden;

//   .mat-drawer-content {
//     overflow: hidden;
//   }
// }

.dca-area {
  // width: 24rem;
  width: calc(100vw - 40px);

  .container-scrollable {
    height: calc(100vh - 64px);
  }
}

.dca-heading {
  background-color: rgba(var(--primary-color-500), 1);
  color: rgba(var(--primary-color-50), 1);
  height: 64px;

  .h2 {
    font-size: 1.25rem;
  }
}

.dca-scrollarea {
  height: calc(100vh - 130px); //64*2 which is +2 px for shadow in 15
  // overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

// @media #{$breakpoint-lg} {
.dca-opened {
  overflow: auto;

  .mat-drawer-content {
    overflow: auto;
  }
}

.dca-area {
  width: var(--dca-width);
}

.dca-opened.dca-expanded {
  overflow: hidden;

  .mat-drawer-content {
    overflow: hidden;
  }

  .dca-menu {
    background-color: rgba(var(--background-card), 1);
  }

  .dca-area {
    // width: 24rem;
    width: calc(100vw - 40px);

    .container-scrollable {
      height: calc(100vh - 64px);
    }
  }
}
// }

// setting z-index to 1 causes mat-manu model open
.cdk-overlay-connected-position-bounding-box {
  // z-index: 1;
}

.cdk-overlay-backdrop {
  z-index: 0;
}

.mat-dialog-container {
  min-width: 40vw;
}

*,
:after,
:before {
  box-sizing: inherit;
}

// editor-style
.cm-content,
.cm-gutter {
  min-height: 4em;
}

.cm-gutters {
  margin: 1px;
}

.cm-scroller {
  overflow: auto;
}

.cm-wrap {
  border: 1px solid silver;
}

// material style
.mat-tooltip.tooltip {
  font-size: 15px;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0 1em 0;
}

.meta-data-editor {
  div[data-theme='html'] {
    border-radius: 0.5rem;
    border: 1px solid rgba(var(--background-selected-button), 1);
    padding: 1rem 0.125rem 0.5rem 0.5rem;

    button {
      border-radius: 0.25rem;
      padding: 0.25rem 0.75rem;
    }

    [data-schemapath='root'] > h3 {
      text-align: center;
      font-size: 1.3rem;
      background: none;
    }

    .je-indented-panel {
      border: none;
      border-bottom: 1px dotted #ddd;
      border-radius: none;
    }

    .je-object__controls {
      padding: 2rem 0 0.25rem 0;
    }

    .je-header {
      background: none;
      font-weight: bold;
      border-bottom: 2px dashed rgba(var(--background-background), 1);
      border-radius: 0;
    }

    label.je-form-input-label {
      font-size: 1rem;
      font-weight: bold;
      margin-top: 0.75rem;
    }

    p.je-form-input-label {
      font-size: 0.9rem;
      font-weight: normal;
    }

    h3 {
      margin-top: 1.25rem;
      font-size: 1.125rem;
    }

    .je-object__controls {
      padding-left: 1rem;
      padding-top: 1rem;
    }

    .form-control {
      input,
      textarea,
      select {
        width: 100%;
        padding: 0.5rem;
        margin: 0.25rem 0;
        display: inline-block;
        border: 1px solid rgba(var(--background-selected-button));
        border-radius: 0.25rem;
        box-sizing: border-box;
        height: auto;
      }
    }
  }
}

$positive: #00ad00;
$negative: #cc071e;
$warning: #ffcc00;

.positive {
  color: $positive;
}

.warning {
  color: $warning;
}

.negative {
  color: $negative;
}

.spacer {
  flex: 1 1 auto;
}
