// DUPLICATE lib/styles/src
// This file is shared between the main app and the history app
// currently we have 2 color schemes in place as well as 2 shell apps
// for the time being this will run in parallel until we have 
// the main app migrated to the current history shell

@function hex-to-color($color) {
  @if ($color == null) {
      @return null;
  }
  @return (red($color), green($color), blue($color));
}

@mixin light-theme {
  // BACKGROUND PALLETE
  --background-status-bar:#{hex-to-color(#e0e0e0)};
  // --background-app-bar:#{hex-to-color(#f5f5f5)};
  --background-app-bar:#{hex-to-color(#fff)};
  --background-background:#{hex-to-color(#fafafa)};
  --background-hover:#{hex-to-color(#000000)};
  --background-card: #{hex-to-color(#ffffff)};
  --background-dialog: #{hex-to-color(#ffffff)};
  --background-disabled-button: #{hex-to-color(#000000)};
  --background-raised-button: #{hex-to-color(#ffffff)};
  --background-focused-button: #{hex-to-color(#000000)};
  --background-selected-button: #{hex-to-color(#e0e0e0)};
  --background-selected-disabled-button: #{hex-to-color(#bdbdbd)};
  --background-disabled-button-toggle: #{hex-to-color(#eeeeee)};
  --background-unselected-chip: #{hex-to-color(#e0e0e0)};
  --background-disabled-list-option: #{hex-to-color(#eeeeee)};
  --background-tooltip: #{hex-to-color(#616161)};
  // unsure if the next two --background-avatar, --background-user-card
  // are material or SciFlow added
  --background-avatar: #{hex-to-color(#cfecf9)};
  --background-user-card: #{hex-to-color(#EAEAEA)};
  // FOREGROUND PALLETE
  --foreground-base: #{hex-to-color(#000000)};
  --foreground-divider: #{hex-to-color(#000000)};
  --foreground-dividers: #{hex-to-color(#000000)};
  --foreground-disabled: #{hex-to-color(#000000)};
  --foreground-disabled-button: #{hex-to-color(#000000)};
  --foreground-disabled-text: #{hex-to-color(#000000)};
  --foreground-elevation: #{hex-to-color(#000000)}; // Setting elevation messes up box shadow percentages, so we do not set it in theming
  --foreground-hint-text: #{hex-to-color(#000000)};
  --foreground-secondary-text: #{hex-to-color(#000000)};
  --foreground-icon: #{hex-to-color(#000000)};
  --foreground-icons: #{hex-to-color(#000000)};
  --foreground-text: #{hex-to-color(#000000)};
  --foreground-slider-min: #{hex-to-color(#000000)};
  --foreground-slider-off: #{hex-to-color(#000000)};
  --foreground-slider-off-active: #{hex-to-color(#000000)};
  --foreground-button: #{hex-to-color(#034b7d)};
  --foreground-button-disabled: #{hex-to-color(#708090)};
}

:root {
  @include light-theme();
  // PRIMARY PALLETE
  --primary-color-50: #{hex-to-color(#E2EDF3)};
  --primary-color-100: #{hex-to-color(#B6D3E2)}; //lighter
  --primary-color-200: #{hex-to-color(#85B5CF)};
  --primary-color-300: #{hex-to-color(#5497BC)};
  --primary-color-400: #{hex-to-color(#3081AD)};
  --primary-color-500: #{hex-to-color(#0B6B9F)}; //default
  --primary-color-600: #{hex-to-color(#0A6397)};
  --primary-color-700: #{hex-to-color(#08588D)}; //darker
  --primary-color-800: #{hex-to-color(#064E83)};
  --primary-color-900: #{hex-to-color(#033C72)};
  --primary-color-A100: #{hex-to-color(#A1CBFF)};
  --primary-color-A200: #{hex-to-color(#6EAEFF)};
  --primary-color-A400: #{hex-to-color(#3B92FF)};
  --primary-color-A700: #{hex-to-color(#2184FF)};
  // SF VARS we can override them in dark mode
  --dca-width: 24rem;
  --dca-menu-width: 40px;
  --added-green: #{hex-to-color(#b4ffb4)};
  --removed-red: #{hex-to-color(#ffb0b0)};

  --grey-dimmed: #{hex-to-color(#CFD8DC)};
  --grey-default: #{hex-to-color(#787679)};
  --grey-high: #{hex-to-color(#59585A)};

  // Angular vars
  --mdc-checkbox-state-layer-size: 28px;
  --mdc-radio-state-layer-size: 28px;

  // --mdc-icon-button-icon-size: 40px;
}

@media (prefers-color-scheme: light) {
  :root {
    @include light-theme();
  }
}

.light-theme {
  @include light-theme();
}

@mixin dark-theme {
  // BACKGROUND PALLETE
  --background-status-bar:#{hex-to-color(#000000)};
  --background-app-bar:#{hex-to-color(#212121)};
  --background-background:#{hex-to-color(#303030)};
  --background-hover:#{hex-to-color(#ffffff)};
  --background-card: #{hex-to-color(#424242)};
  --background-dialog: #{hex-to-color(#424242)};
  --background-disabled-button: #{hex-to-color(#ffffff)};
  --background-raised-button: #{hex-to-color(#424242)};
  --background-focused-button: #{hex-to-color(#ffffff)};
  --background-selected-button: #{hex-to-color(#212121)};
  --background-selected-disabled-button: #{hex-to-color(#424242)};
  --background-disabled-button-toggle: #{hex-to-color(#000000)};
  --background-unselected-chip: #{hex-to-color(#616161)};
  --background-disabled-list-option: #{hex-to-color(#000000)};
  --background-tooltip: #{hex-to-color(#616161)};
  // unsure if the next two --background-avatar, --background-user-card
  // are material or SciFlow added
  --background-avatar: #{hex-to-color(#2e818c)};
  --background-user-card: #{hex-to-color(#7d7b7b)}; 
  // FOREGROUND PALLETE
  --foreground-base: #{hex-to-color(#ffffff)};
  --foreground-divider: #{hex-to-color(#ffffff)};
  --foreground-dividers: #{hex-to-color(#ffffff)};
  --foreground-disabled: #{hex-to-color(#ffffff)};
  --foreground-disabled-button: #{hex-to-color(#ffffff)};
  --foreground-disabled-text: #{hex-to-color(#ffffff)};
  --foreground-elevation: #{hex-to-color(#000000)};
  --foreground-hint-text: #{hex-to-color(#ffffff)};
  --foreground-secondary-text: #{hex-to-color(#ffffff)};
  --foreground-icon: #{hex-to-color(#ffffff)};
  --foreground-icons: #{hex-to-color(#ffffff)};
  --foreground-text: #{hex-to-color(#ffffff)};
  --foreground-slider-min: #{hex-to-color(#ffffff)};
  --foreground-slider-off: #{hex-to-color(#ffffff)};
  --foreground-slider-off-active: #{hex-to-color(#ffffff)};
  --foreground-button: #{hex-to-color(#ffffff)};
  --foreground-button-disabled: #{hex-to-color(#708090)};
  
}
  
// FIXME #665
@media (prefers-color-scheme: dark) {
  :root {
    @include dark-theme();
    // NTS: check alternatives this is not maintanable
    // extras dark overrides, some are post processed so we need rgba
    // --mat-option-selected-state-label-text-color: rgba(#{hex-to-color(#ffffff)});
    // --mat-select-focused-arrow-color: rgba(#{hex-to-color(#B6D3E2)});
    // --mdc-filled-text-field-focus-label-text-color: rgba(#{hex-to-color(#B6D3E2)});
    // --mdc-text-button-label-text-color: rgba(#{hex-to-color(#B6D3E2)});
    // --primary-color-500: #{hex-to-color(#B6D3E2)}; //default
  }
}

.dark-theme {
  @include dark-theme();
  --added-green: #{hex-to-color(#455845)};
  --removed-red: #{hex-to-color(#822828)};

  --grey-dimmed: #{hex-to-color(#909090)};
  --grey-default: #{hex-to-color(#B0ADAB)};
  --grey-high: #{hex-to-color(#D4D4D4)};
}
  