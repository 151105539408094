$footnote__height: 18px;
$footnote__width: 18px;
$sf-dark-blue: #0b6a9f;
$sf-light-blue: #0b6a9f;

$footnote__bg--color: #0b6a9f;

footnote {
    display: inline-block;
    cursor: pointer;
    outline: none !important;
    width: 0.5em;
    border-radius: 0.25rem;
    height: calc(18px * 1.62); //font-size * line-height
    padding-left: $footnote__width;
}

footnote::after {
    position: absolute;
    content: "";
    background: url('../../src/assets/sf-icons/footnote.svg') center center/$footnote__width $footnote__height no-repeat;
    height: $footnote__height;
    width: $footnote__width;
    transform: translateX(-100%);
    background-color: #fafafa;
}

.ProseMirror-hideselection .footnote-tooltip *::selection {
    background-color: transparent;
}

.ProseMirror-hideselection .footnote-tooltip *::-moz-selection {
    background-color: transparent;
}

.footnote-tooltip {
    cursor: auto;
    position: absolute;
    z-index: 1000;
    left: 0;
    margin-top: 35px;
    width: 100%;
    .ProseMirror {
        padding: 0.75rem;
    }
    .header {
        background: $footnote__bg--color;
        color: #ffffff;
        font-size: 0.75rem;
        font-family: Noto Sans, sans-serif;
        width: 4.25rem;
        padding-top: 0.1rem;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        text-align: center;
    }
    .body {
        border: 3px solid $footnote__bg--color;
        // @include shadow();
        border-top-right-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background: #fff;
        font-size: 80%;
        color: #053149;
        padding: 0;
    }
    .js-body-empty {
        min-height: calc( 18px * 1.62 + 24px); //editor font size * line height + vertical padding 
        &:after {
            background-color: #eee;
            color: #90a4ae; // color: #053149;
            // position: absolute;
            // top: calc(100% / 2);
            // right: 1em;
            // content: "Add your footnote here";
        }
    }
}

// the tooltip will be rendered inside the figure element which will make it only display partly
td * .footnote-tooltip {
    position: fixed;
    left: 2em;
}

.footnote-tooltip:after {
    display: block;
    text-align: right;
    font-size: 0.75rem;
    color: #555;
    content: "Press Esc to exit";
}