.fc-grey{
    color: rgba(var(--background-tooltip), 1);
}

// DUPLICATE: main2/_utils
.uppercase {
    text-transform: uppercase;
}
// DUPLICATE: main2/_utils
.small {
    font-size: 0.85rem;
}
// DUPLICATE: main2/_utils
.cursor-pointer {
    cursor: pointer;
}
.opacity {
    opacity: 0.5;
}
// DUPLICATE: main2/_utils
.border-radius-0 {
    border-radius: 0;
}