.js-cross-referencing-chip-wrapper {
    display: inline;
    position: relative;
}

.js-cross-referencing-chip-wrapper .js-cross-referencing-chip {
    font-size: 0.95rem;
    cursor: pointer;
    margin: 0 0.1rem;
    -webkit-user-select: all;
    -moz-user-select: all;
    user-select: all;
    border-radius: 0.25rem;
    vertical-align: middle;
    white-space: normal;
    white-space: initial;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.js-cross-referencing-chip-wrapper .js-cross-referencing-chip-tooltip {
    position: absolute;
    left: 50%;
    top: -2.15rem;
}

.js-cross-referencing-chip-wrapper .js-cross-referencing-chip-missing-ref {
    border: 2px solid red;
    border-radius: 5px;
}

.js-cross-referencing-chip-wrapper .js-cross-referencing-chip-tooltip div {
    position: relative;
    left: -50%;
    display: none;
    padding: 0.375rem 0.5rem;
    width: auto;
    max-width: 16rem;
    z-index: 1;
    border-radius: 0.125rem;
    background: rgba(97, 97, 97, 0.9);
    color: white;
    font-family: "Roboto Condensed";
    font-size: 0.625rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}